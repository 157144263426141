import { createActionGroup, emptyProps } from '@ngrx/store';

const PREFIX = '[Auth API]';

export const AuthActions = createActionGroup({
  source: `${PREFIX} Root`,
  events: {
    logout: emptyProps(),
  },
});
